<template>
  <div class="category">
    <a-button type="primary" @click="onAddClick">新增分类</a-button>
    <!-- 分类列表 -->
    <a-table
      :columns="columns"
      :loading="loading"
      :dataSource="dataSource"
      rowKey="_id"
      :pagination="false"
      style="margin-top: 16px"
    >
      <template slot="create_by" slot-scope="text, record">
        <div>{{ record.create_by ? record.create_by.username : '-' }}</div>
        <div>
          {{
            record.create_at
              ? $dayjs(record.create_at).format('YYYY-MM-DD HH:mm:ss')
              : '-'
          }}
        </div>
      </template>
      <template slot="update_by" slot-scope="text, record">
        <div>{{ record.update_by ? record.update_by.username : '-' }}</div>
        <div>
          {{
            record.update_at
              ? $dayjs(record.update_at).format('YYYY-MM-DD HH:mm:ss')
              : '-'
          }}
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-space>
          <a type="primary" size="small" @click="onEditClick(record)"> 编辑 </a>
          <a
            type="danger"
            size="small"
            @click="onDeleteClick(record)"
            style="color: #f30"
          >
            删除
          </a>
        </a-space>
      </template>
    </a-table>
    <!-- 新增/编辑 弹窗 -->
    <CategoryFormModal ref="categoryFormModal" @ok="getCategoryList" />
  </div>
</template>

<script>
import CategoryFormModal from '@/components/CategoryFormModal';

export default {
  name: 'ArticleCategory',
  components: { CategoryFormModal },
  data() {
    return {
      loading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 80,
          align: 'center',
          customRender: (text, record, index) => {
            return index + 1;
          },
        },
        {
          title: '分类名称',
          dataIndex: 'name',
          width: 150,
          align: 'center',
        },
        {
          title: '分类描述',
          dataIndex: 'summary',
        },
        {
          title: '文章数量',
          dataIndex: 'article_count',
          width: 100,
          align: 'center',
        },
        {
          title: '创建人',
          dataIndex: 'create_by',
          scopedSlots: { customRender: 'create_by' },
          width: 200,
        },
        {
          title: '更新人',
          dataIndex: 'update_by',
          scopedSlots: { customRender: 'update_by' },
          width: 200,
        },
        {
          title: '排序',
          dataIndex: 'sort',
          width: 100,
          align: 'center',
        },
        {
          title: '操作',
          key: 'operation',
          width: 100,
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      dataSource: [],
    };
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    async getCategoryList() {
      this.loading = true;
      const { err, res } = await this.$api.article.categoryList();
      if (!err) {
        this.dataSource = res.result.list;
      }
      this.loading = false;
    },
    // 点击编辑
    onEditClick(record) {
      this.$refs.categoryFormModal.open({
        type: 'edit',
        detail: {
          name: record.name,
          summary: record.summary,
          sort: record.sort,
          _id: record._id,
        },
      });
    },
    // 点击新增
    onAddClick() {
      this.$refs.categoryFormModal.open({
        type: 'add',
      });
    },
    // 点击删除
    onDeleteClick(record) {
      this.$confirm({
        title: '提示',
        content: `确定要删除分类【${record.name}】吗？`,
        okText: '确定',
        cancelText: '取消',
        center: true,
        onOk: async () => {
          const { err, res } = await this.$api.article.deleteCategory({
            _id: record._id,
          });
          if (!err) {
            this.$message.success(res.msg);
            this.getCategoryList();
          }
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
